import { partition } from 'lodash';
import { ExtractPropTypes, ToRefs, unref } from 'vue';
import { CREATE_ITEM_ID, OmniSearchItem, OmniSearchLocalDatasetProps } from './omniSearch.types';

export function extractExactMatches<T extends OmniSearchItem>(
  rawSearchResults: T[],
  itemDisplayNameKey: string,
  searchText: string | null,
) {
  const lowerSearchText = (searchText ?? '').toLowerCase();
  const [exactMatches, remainingItems] = partition(rawSearchResults, (item) => {
    return (item[itemDisplayNameKey] as string)?.toLowerCase() === lowerSearchText;
  });

  return [exactMatches, remainingItems];
}

export function appendCreateItem<T extends OmniSearchItem>(
  results: T[],
  currentSearchText: string | null,
  {
    allowCreate,
    itemIdentifier,
    itemDisplayNameKey,
  }: Prettify<
    ToRefs<
      ExtractPropTypes<
        Pick<
          typeof OmniSearchLocalDatasetProps,
          'allowCreate' | 'itemIdentifier' | 'itemDisplayNameKey'
        >
      >
    >
  >,
) {
  // If there is an exact match in the results, don't show a create option
  // Because allowCreate causes exact matches to be pulled to the top, we only have
  // to look at the first result to check this
  const lowerSearchText = currentSearchText?.toLowerCase() ?? '';
  const firstResult = results[0];
  if (firstResult && firstResult[unref(itemDisplayNameKey)]?.toLowerCase() === lowerSearchText) {
    return results;
  }

  if (unref(allowCreate) === 'when-empty' && results.length > 0) {
    return results;
  }

  return [
    ...results,
    {
      [unref(itemIdentifier)]: CREATE_ITEM_ID,
      [unref(itemDisplayNameKey)]: 'Create',
    },
  ];
}
