<template>
  <OmniSearch
    v-if="!currentSelection"
    class="w-100"
    :disabled="disabled"
    :placeholder="placeholder"
    :input-id="id"
    :input-name="name"
    v-bind="$attrs"
    @blur="$emit('blur')"
  >
    <OmniSearchFetchedDataset
      v-if="searchType === LookupSearchType.Local"
      id="fetched-lookup-data"
      :fetch-items="fetchItems"
      v-bind="datasetProps"
      @select="selectItem"
      @create="createItem"
    >
      <template #create-item="{ searchText }">
        <span class="text-primary">Create new "{{ searchText }}"</span>
      </template>
    </OmniSearchFetchedDataset>
    <OmniSearchRemoteDataset
      v-else
      id="remote-lookup-data"
      :search-items="searchItems"
      v-bind="datasetProps"
      @select="selectItem"
    />
  </OmniSearch>
  <BaseSearchInput
    v-else
    class="w-100 latched-input"
    :model-value="currentSelectionName"
    :icon="false"
    disabled
  >
    <template #rightIcon>
      <BaseIcon
        v-if="!disabled"
        class="cursor-pointer p-2 mr-n1"
        role="button"
        name="cancel"
        theme="outlined"
        :size="16"
        @click="clearSelection"
      />
    </template>
  </BaseSearchInput>
</template>

<style lang="scss" scoped>
.latched-input :deep(input)[disabled] {
  // input is disabled but we don't want to grey it out
  opacity: 1;
  // Hack to make Safari also work:
  // https://stackoverflow.com/a/43483479
  // https://bugs.webkit.org/show_bug.cgi?id=115510
  -webkit-text-fill-color: $input-color;
}
</style>

<script lang="ts">
import { PropType, computed, defineComponent, inject, ref, unref } from 'vue';
import { isObject, memoize } from 'lodash';
import { FULLY_COMPATIBLE } from '@productiv/compass/utils/compat';
import {
  BaseIcon,
  BaseSearchInput,
  OmniSearch,
  OmniSearchFetchedDataset,
  OmniSearchRemoteDataset,
} from '@productiv/compass/components';
import { LookupScope, LookupSearchType } from '../forms.types';
import { CUSTOM_FORM_API_CALLER_KEY } from '../constants';
import { LookupResult } from './lookup';

export default defineComponent({
  name: 'LookupField',
  components: {
    BaseIcon,
    BaseSearchInput,
    OmniSearch,
    OmniSearchFetchedDataset,
    OmniSearchRemoteDataset,
  },
  compatConfig: FULLY_COMPATIBLE,
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    scope: {
      type: String as PropType<LookupScope>,
      required: true,
    },
    searchType: {
      type: String as PropType<LookupSearchType>,
      required: true,
    },
    itemIdKey: {
      type: String,
      required: true,
    },
    itemDisplayNameKey: {
      type: String,
      required: true,
    },
    allowCreate: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object, // This is the submitted object, _not_ wrapped in a LookupResult
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: undefined,
    },
  },
  emits: {
    input: (result: LookupResult<unknown> | undefined) => !result || isObject(result),
    blur: () => true,
  },
  setup: (props, { emit }) => {
    const currentSelection = ref<LookupResult | undefined>(undefined);
    if (props.value) {
      currentSelection.value = { type: 'select', selection: props.value };
      // Need to normalize to our "intermediate representation" for FormKit while the form is being filled out
      emit('input', currentSelection.value);
    }

    const apiCaller = inject(CUSTOM_FORM_API_CALLER_KEY);
    // fetchItems only applies when searchType is Local
    // searchItems only applies when searchType is Remote

    // We memoize this function here because the OmniSearchFetchedDataset component unmounts and
    // re-mounts when selecting and then clearing a selection, and we don't want to re-fetch the
    // data in that case.
    const fetchItems = memoize(() => {
      return apiCaller!(() => ({
        url: '/paws/forms/lookup-list',
        params: {
          scope: props.scope,
        },
      }));
    });
    const searchItems = (searchText: string) => {
      return apiCaller!(() => ({
        url: '/paws/forms/lookup',
        params: {
          scope: props.scope,
          query: searchText,
        },
      }));
    };

    const currentSelectionName = computed(() => {
      const selection = unref(currentSelection);
      if (!selection) {
        return '';
      }
      return selection.type === 'create'
        ? selection.text
        : selection.selection[props.itemDisplayNameKey];
    });

    const selectItem = (item: any) => {
      currentSelection.value = { type: 'select', selection: item };
      emit('input', currentSelection.value);
    };

    const createItem = (searchText: string) => {
      currentSelection.value = { type: 'create', text: searchText };
      emit('input', currentSelection.value);
    };

    const clearSelection = () => {
      currentSelection.value = undefined;
      emit('input', undefined);
    };

    const datasetProps = computed(() => ({
      itemIdentifier: props.itemIdKey,
      itemDisplayNameKey: props.itemDisplayNameKey,
      allowCreate: props.allowCreate,
      showAppSearchIcon: false,
      hideHeader: true,
    }));

    return {
      fetchItems,
      searchItems,
      currentSelection,
      currentSelectionName,
      selectItem,
      createItem,
      clearSelection,
      datasetProps,
      LookupSearchType,
    };
  },
});
</script>
